<template>
  <div class="menu w-72 h-screen">
    <div class="fixed w-72 h-screen">
      <div
        class="bg-nColorTextInput min-h-[calc(100vh-15px)] p-8 m-2 rounded-2xl relative"
      >
        <router-link
          :to="{
            name: 'Dashboard',
          }"
        >
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda-logo-dark.webp"
            alt="Playda Logo"
            width="130"
            height="56"
          />
        </router-link>
        <div class="grid gap-4 mt-4" v-if="data">
          <div class="flex gap-2 items-center justify-between">
            <div class="flex gap-2 items-center">
              <div class="w-3 h-3 bg-white rounded-full"></div>
              <p class="font-InterSemiBold text-white text-sm">Unused</p>
            </div>
            <div
              class="text-nColorYellow bg-nColorButtonText rounded px-2 py-1"
            >
              <p class="font-RobotoBlack text-lg">
                {{ formatNumber(data.available) }}
              </p>
            </div>
          </div>
          <div class="flex gap-2 items-center justify-between">
            <div class="flex gap-2 items-center">
              <div class="w-3 h-3 bg-nColorOrange rounded-full"></div>
              <p class="font-InterSemiBold text-nColorOrange text-sm">
                Incomplete
              </p>
            </div>
            <div
              class="text-nColorYellow bg-nColorButtonText rounded px-2 py-1"
            >
              <p class="font-RobotoBlack text-lg">
                {{ formatNumber(data.incomplete) }}
              </p>
            </div>
          </div>
          <div class="flex gap-2 items-center justify-between">
            <div class="flex gap-2 items-center">
              <div class="w-3 h-3 bg-nColorBlue rounded-full"></div>
              <p class="font-InterSemiBold text-nColorBlue text-sm">Ready</p>
            </div>
            <div
              class="text-nColorYellow bg-nColorButtonText rounded px-2 py-1"
            >
              <p class="font-RobotoBlack text-lg">
                {{ formatNumber(data.ready) }}
              </p>
            </div>
          </div>
          <div class="flex gap-2 items-center justify-between">
            <div class="flex gap-2 items-center">
              <div class="w-3 h-3 bg-nColorStatusGreen rounded-full"></div>
              <p class="font-InterSemiBold text-nColorStatusGreen text-sm">
                Playing
              </p>
            </div>
            <div
              class="text-nColorYellow bg-nColorButtonText rounded px-2 py-1"
            >
              <p class="font-RobotoBlack text-lg">
                {{ formatNumber(data.playing) }}
              </p>
            </div>
          </div>
          <div class="flex gap-2 items-center justify-between">
            <div class="flex gap-2 items-center">
              <div class="w-3 h-3 bg-nColorLightGray rounded-full"></div>
              <p class="font-InterSemiBold text-nColorLightGray text-sm">
                Played
              </p>
            </div>
            <div
              class="text-nColorYellow bg-nColorButtonText rounded px-2 py-1"
            >
              <p class="font-RobotoBlack text-lg">
                {{ formatNumber(data.played) }}
              </p>
            </div>
          </div>
        </div>
        <div class="absolute bottom-8 w-9/12">
          <div class="grid gap-2 mt-6">
            <p class="font-InterMedium text-nColorLightGray text-sm">
              Can't find something you're looking for?
            </p>
            <router-link
              :to="{
                name: 'ContactUs',
              }"
              class="w-5/6 text-center font-ZuumeMedium border border-nColorYellow text-nColorYellow px-6 py-1 rounded-3xl text-2xl"
            >
              Contact Us
            </router-link>
          </div>
          <div class="grid gap-1 mt-10">
            <p class="text-nColorLightGray text-sm">
              Buy a plan & Create more Game Jams!
            </p>
            <button
              class="w-5/6 font-ZuumeMedium text-nColorButtonText bg-nColorYellow px-6 py-1 rounded-3xl mt-2 text-2xl"
              @click.prevent="goToPricing()"
            >
              Buy
            </button>
          </div>
          <div class="grid gap-1 mt-4">
            <router-link
              :to="{
                name: 'PurchaseHistory',
              }"
            >
              <div class="text-nColorYellow underline font-InterSemiBold">
                View Purchase History
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  name: "DesktopMenu",
  data() {
    return {
      username: this.$store.getters.profile.name,
      email: this.$store.getters.profile.email,
      data: null,
    };
  },
  created() {
    ApiService.get(apiResource.getDashboardMenuNumbers, {}).then((data) => {
      this.data = data.data.data;
    });
  },
  methods: {
    goToDashboard() {
      this.$router.push({
        name: "Dashboard",
      });
    },
    signOut() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Login",
      });
    },
    goToPricing() {
      this.$router.push({
        name: "Pricing",
      });
    },
    formatNumber(value) {
      return String(value).padStart(2, "0");
    },
  },
};
</script>

<style scoped>
.menu {
  height: 100dvh;
}
</style>
